import { Collapse } from 'bootstrap';

import ENV from "./config.env";

// arrays & objs
export function objectEquals(x, y) {
  return (x && y && typeof x === 'object' && typeof y === 'object') ?
    (Object.keys(x).length === Object.keys(y).length) &&
      Object.keys(x).reduce(function(isEqual, key) {
        return isEqual && objectEquals(x[key], y[key]);
      }, true) : (x === y);
}
export function arrayIntersperse(arr, getSeparator) {
  if(!arr?.length)
    return arr;
  const lastElt = arr.pop();
  const out = [];
  arr.forEach(elt=>{
    out.push(elt);
    out.push(getSeparator());
  });
  out.push(lastElt);
  return out;
}
export function arrayDedupe(arr, equalsFunc) {
  const out = [];
  if(!equalsFunc)
    equalsFunc = (a,b)=>a===b;
  arr.forEach(elt=>{
    if(out.find(outElt=>equalsFunc(outElt, elt))===undefined)
      out.push(elt);
  });
  return out;
}
export function arrayExtract(arr, extractFunc) {
  //console.log('arrayExtract', arr);
  const extracted = [];
  for(var i=0; i<arr.length; ++i) {
    const val = arr[i];
    if(extractFunc(val)) {
      extracted.push(val);
      arr.splice(i,1);
      i--;
    }
  }
  return extracted;
}
export function arrayRemoveValue(arr, val, absentOk=false) {
  const index = arr.indexOf(val);
  if(index!==-1)
    arr.splice(index, 1);
  else    
    assert(absentOk);
}

// ui / rendering
export function capitalizeFirstLetter(string) {
  if(!string)
    return '';
  if(string.slice(0,5)==='(to) ')
    return string.slice(0,5) + string.charAt(5).toUpperCase() + string.slice(6);
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function setCollapseVisibility(collapseRef, visible) {
  assert(collapseRef.current);
  const bsCollapse = new Collapse(collapseRef.current);
  if(visible)
    bsCollapse.show();
  else
    bsCollapse.hide();
}
export function hideCollapse(collapseRef) {
  setCollapseVisibility(collapseRef, false);
}
export function showCollapse(collapseRef) {
  setCollapseVisibility(collapseRef, true);
}

// other
export function assert(isOk, msg) {
  if(!isOk)
    throw new Error(msg);
}
export function isAppStandalone() {
  return window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone || document.referrer.startsWith("android-app://");
}

// payments
export function getSubscriptionLink(user) {
  const baseUrl = (ENV==='dev') ? 'https://buy.stripe.com/test_fZe29Z8vs7Gu7VSbII' : 'https://buy.stripe.com/14k4gU5ZP2sVe2c9AA';
  return `${baseUrl}?prefilled_email=${user.email}&client_reference_id=${user.id}`;
}