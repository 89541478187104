import http from "./http-common";
import { assert } from '../common/utility';

function getAPIPath(path) {
  return `/user`+(path?`/${path}`:'');
}
async function userAPICall(type, path, data=null) {
  path = getAPIPath(path);
  var out;
  switch(type) {
  case 'get':
    out = await http().get(path);
    break;
  case 'put':
    out = await http().put(path, data);
    break;
  case 'delete':
    out = await http().delete(path, data);
    break;
  default:
    assert(false);
  }
  console.log(`userAPI`, type, path, out);
  out = out.data;
  return out;
}

class UserDataService {
  async getUser() {
    return await userAPICall('get', '');
  }
  async getMapData() {
    return await userAPICall('get', 'mapData');
  }
  async addList(url) {
    return await userAPICall('put', 'addList', { url });
  }
  async deleteList(id) {
    return await userAPICall('put', 'deleteList', { id });
  }
  async addTag(placeId, type, name) {
    return await userAPICall('put', 'addTag', { placeId, type, name });
  }
  async renameTag(oldName, newName) {
    return await userAPICall('put', 'renameTag', { oldName, newName });
  }
  async mergeTags(baseTag, mergeInTag) {
    return await userAPICall('put', 'mergeTags', { baseTag, mergeInTag });
  }
  async deleteTagGlobal(name) {
    return await userAPICall('put', 'deleteTagGlobal', { name });
  }
  async deleteTag(placeId, type, name) {
    return await userAPICall('put', 'deleteTag', { placeId, type, name });
  }
  async addRegion(name, path) {
    return await userAPICall('put', 'addRegion', { name, path });
  }
  async deleteRegion(regionId) {
    return await userAPICall('put', 'deleteRegion', { regionId });
  }
  async incMeterMapRefresh() {
    return await userAPICall('put', 'incMeterMapRefresh', {});
  }
  async cancelSubscription() {
    return await userAPICall('put', 'cancelSubscription', {});
  }
  async reactivateSubscription() {
    return await userAPICall('put', 'reactivateSubscription', {});
  }
}

const userDataService = new UserDataService();
export default userDataService;
