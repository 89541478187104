import { useContext, createContext } from "react";

import useTimer from "./timer.hook";

import { hideCollapse, showCollapse } from '../common/utility';

export const HeaderContext = createContext();

let places = [], hiddenPlaces = [];
export function useHeader() {
  const timer = useTimer();

  const {
    searchText, setSearchText,
    view, setView,
    showSearchHints, setShowSearchHints,
    numLists, setNumLists,
  } = useContext(HeaderContext);

  function onSearchChange(e) {
    const newVal = e.target.value;
    setSearchText(newVal);
    if(!newVal && view==='list')
      setView('map');
  }
  function clearSearch() {
    setSearchText('');
    if(view==='list')
      setView('map');
  }
  function toggleView() {
    setView(view==='map' ? 'list' : 'map');
  }
  function toggleSearchHints() {
    setShowSearchHints(!showSearchHints);
  }
  function updatePlaces(newPlaces, newHiddenPlaces) {
    places = newPlaces;
    hiddenPlaces = newHiddenPlaces;
  }  
  function getNumPlaces() {
    return places.length;
  }
  function doExport(alertRef) {
    return ()=>{
      let str = '';
      for(const place of places) {
        if(!hiddenPlaces.includes(place.id))
          str += `${place.name}\n${place.url}\n\n`;
      }
      navigator.clipboard.writeText(str);
      showCollapse(alertRef);
      timer.fn(() => {
        hideCollapse(alertRef);
      }, 2000);
    };
  }
  
  return {
    searchText,
    onSearchChange,
    clearSearch,
    
    view,
    toggleView,
    
    showSearchHints,
    toggleSearchHints,
    
    numLists,
    setNumLists,
    
    updatePlaces,
    getNumPlaces,
    doExport,
  };
}