import { useRef, useState } from "react";

import Loading from "./loading.component";
import NeedSubscribe from "./needSubscribe.component";

import UserDataService from "../services/user.service";

import { arrayDedupe, capitalizeFirstLetter } from '../common/utility';

import useAuth from "../hooks/auth.hook";
import useData from "../hooks/data.hook";
import useTimer from "../hooks/timer.hook";

let actingOnTag = null;
export default function Tags(props) {
  const { authed, userId } = useAuth();
  const { user } = useData('user', {authed});
  const { mapData, processResult } = useData('mapData');
  const timer = useTimer();

  const [alert, setAlert] = useState(null);
  const [showRename, setShowRename] = useState(false);
  const [showMerge, setShowMerge] = useState(false);
  const renameInput = useRef();
  const mergeSelect = useRef();
      
  console.log('Tags - render', mapData);
  
  // loading screen
  if(!authed || !user) {
    return (<Loading />);
  }
  else if(!mapData) {
    if(!!user.subscription) {
      return (<Loading />);
    }
    else {
      return (<NeedSubscribe user={user} />);
    }
  }
  
  // tags
  let uniqueTags = [];
  if(mapData) {
    function getPlaceTextTags(place) {
      return place.tags?.filter(t=>t.type==='text' && t.userId===userId).map(t=>t.name) ?? [];
    }
    mapData.places.forEach(p=>{
      if(p.tags)
        uniqueTags = uniqueTags.concat(getPlaceTextTags(p));
    });
    uniqueTags = arrayDedupe(uniqueTags);
  }
  
  // tag actions
  function showAlert(message, type='primary') {
    setAlert({
      message,
      type
    });
    timer.fn(() => {
      setAlert(null);
    }, 3000);
  }
  function startRenameTag(name) {
    return ()=>{
      actingOnTag = name;
      setShowMerge(false);
      setShowRename(true);
    };
  }
  function startMergeTag(name) {
    return ()=>{
      actingOnTag = name;
      setShowMerge(true);
      setShowRename(false);
    };
  }
  function finishRenameTag() {
    const newName = renameInput.current.value;
    if(newName==='') {
      showAlert('Please enter a name');
      return;
    }
    UserDataService.renameTag(actingOnTag, newName)
    .then(result => {
      processResult(result);
      renameInput.current.value = '';
      setShowRename(false);
      showAlert('Tag renamed');
    });
  }
  function finishMergeTag() {
    const withName = mergeSelect.current.value;
    if(withName==='') {
      showAlert('Please select a tag');
      return;
    }
    UserDataService.mergeTags(actingOnTag, withName)
    .then(result => {
      processResult(result);
      setShowMerge(false);
      showAlert('Tags merged');
    });
  }
  function deleteTag(name) {
    return ()=>{
      UserDataService.deleteTagGlobal(name)
      .then(result => {
        processResult(result);
        showAlert('Tag deleted');
      });
    };
  }
   
  return (
    <>
      { !!alert &&
        <div class={`alert alert-${alert.type} d-flex align-items-center`}>
          <i class="bi bi-exclamation-triangle-fill me-2"></i>
          <div>{alert.message}</div>
        </div>
      }
      <div class="p-425 p-sm-5 bg-light rounded-3">
        <h1 class="mb-4">
          Manage Tags
        </h1>
        <div class="p-3 py-2 p-sm-45 py-sm-4 bg-white rounded-3">
          { !!uniqueTags.length ?
            <table class="table mb-0 w-100 h-100" style={{tableLayout:'fixed'}}>
              <tbody>
                { uniqueTags.map((name, index)=>(
                  <tr class="mb-0" key={index}>
                    <td class="fs-5 fs-sm-3 w-sm-75 w-50">{capitalizeFirstLetter(name)}</td>
                    <td class="fs-5 fs-sm-3 text-muted text-end" style={{verticalAlign:'middle'}}>
                      <i class="bi bi-pencil btn-icon me-2 me-sm-3" onClick={startRenameTag(name)}></i>
                      <i class="bi bi-intersect btn-icon me-2 me-sm-3" onClick={startMergeTag(name)}></i>
                      <i class="bi bi-trash btn-icon" onClick={deleteTag(name)}></i>
                    </td>
                  </tr>
                )) }
              </tbody>
            </table>
          :
            <>No tags yet</>
          }
        </div>
        { showRename &&
          <div class="d-flex pb-0 mt-4">
            <div class="me-4">
              <label for="renameInput" class="text-muted fs-4">New tag name</label>
            </div>
            <div class="flex-grow-1 me-4">
              <input type="text" class="form-control" id="renameInput" ref={renameInput} />
            </div>
            <button type="button" class="btn btn-primary px-4" onClick={finishRenameTag}>Rename</button>
          </div>
        }
        { showMerge &&
          <div class="d-flex pb-0 mt-4">
            <div class="me-4">
                <label for="mergeSelect" class="text-muted fs-4">Merge into tag</label>
            </div>
            <div class="flex-grow-1 me-4">
              <select class="form-select" id="mergeSelect" ref={mergeSelect}>
                { uniqueTags.filter(tagName=>tagName!==actingOnTag).map(tagName=>
                  <option value={tagName}>{capitalizeFirstLetter(tagName)}</option>
                )}
              </select>
            </div>
            <button type="button" class="btn btn-primary px-4" onClick={finishMergeTag}>Merge</button>
          </div>
        }
      </div>
    </>
  );
}