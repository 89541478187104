import { useEffect, useLayoutEffect, useRef, createRef, useState } from "react";
import { Link } from "react-router-dom";

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { capitalizeFirstLetter, arrayDedupe, arrayExtract, arrayRemoveValue, assert, objectEquals, getSubscriptionLink } from '../common/utility';

import UserDataService from "../services/user.service";
import * as demoData from "../common/demoData.json";

import Loading from "./loading.component";
import NeedSubscribe from "./needSubscribe.component";
import Combobox from "./combobox.component";

import useAuth from "../hooks/auth.hook";
import useData from "../hooks/data.hook";
import useForceUpdate from "../hooks/forceUpdate.hook";
import { useHeader } from "../hooks/header.hook";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

let map;
let mapContainerInner;
let mapLoading = false;
const markers = [];
let AdvancedMarkerElement, PinElement, LatLngBounds, Geometry;
let polygons = [];
const hoverStack = [];
const hoverBoxRef = createRef();
const selectPlaceInnerRef = createRef();
const mapClickRef = createRef();
let needBoundsRefit = false;

let newRegionPolyline = null;
let newRegionMarkers = [];
let newRegionName = null;
let regionDeleteMode = false;
const regionBeginDeleteRef = createRef();
const regionBeginDrawRef = createRef();
const deleteRegionRef = createRef();

let lastScrollPos, needScrollFix = false;

let places = [];
let placeRegions = {};
let hiddenPlaces = [];
let place=null;
let lastHighlightedPlace;
let lastSearch='';

export default function Dashboard(props) {
  const { authed, userId } = useAuth();
  const { user } = useData('user', {authed});
  let { mapData, processResult, forceReloadMapData } = useData('mapData', { authed });
  const forceUpdate = useForceUpdate();
  const header = useHeader();

  const [ currentPlace, setCurrentPlace ] = useState(null);
  const [ placeView, setPlaceView ] = useState('info');
  const mapRef = useRef();
  const newRegionNameInput = useRef();
  const newRegionNameBox = useRef();
    
  // handle demo mode
  const demoMode = props.mode==='demo';
  if(demoMode) {
    mapData = demoData;
  }
    
  // place helpers
  function getPlaceTypesDisplay(place) {
    return place.types.filter(t=>!['point_of_interest', 'establishment', 'tourist_attraction'].includes(t) && !t.includes('locality')).map(t=>capitalizeFirstLetter(t.replaceAll('_', ' '))).join(', ');
  }
  function getPlacePrimaryTypeDisplay(place) {
    let text = place.primaryType;
    if(!text) {
      text = '';
      if(place.types.includes('political'))
        text = "City";
    }
    return text;
  }
  function getPlaceTextTags(place) {
    return place?.tags?.filter(t=>t.type==='text').map(t=>({
      name:t.name,
      userId:t.userId,
    })) ?? [];
  }
  function updatePlaceRegions() {
    mapData.places.forEach(place=>{
      const pr = placeRegions[place.id] = [];
      mapData.regions.forEach(region=>{
        const polygon = polygons.find(p=>p.regionId===region.id);
        // eslint-disable-next-line
        if(polygon && Geometry.poly.containsLocation(new google.maps.LatLng(place.gpsCoord.coordinates[0], place.gpsCoord.coordinates[1]), polygon)) {
          pr.push(region);
        }
      });
    });
  }
  function getPlaceRegionNames(place) {
    return placeRegions[place?.id]?.map(r=>r.name.toLowerCase()) ?? [];
  }
  function getPlaceVisits(place) {
    return place?.tags?.filter(t=>t.type==='visitTimestamp').map(t=>({
      time:t.time,
      userId:t.userId,
    })) ?? [];
  }
  function getPlaceLL(place) {
    return {
      lat:place.gpsCoord.coordinates[0],
      lng:place.gpsCoord.coordinates[1],
    };
  }
  function getPlaceListNames(place) {
    return place?.lists.map(listId=>mapData.lists.find(l=>l.id===listId).name) ?? [];
  }
  function refreshData() {
    forceReloadMapData();
  }

  function makeHoverable(type, item, text) {
    let getEventCoords;
    function positionHover(e) {
      let {x,y} = getEventCoords(e);
      x += 5;
      y += 20;
      hoverBoxRef.current.style.top = `${y}px`;
      hoverBoxRef.current.style.left = `${x}px`;
      //console.log('mouseMove', type, hoverStack);
    }
    function mouseMove(e) {
      if(type===hoverStack.at(-1).type)
        positionHover(e);
    }
    function mouseOver(e) {
      //console.log('mouseOver', type);
      hoverStack.push({ type, text });
      if(hoverStack.length===1)
        hoverBoxRef.current.style.display = 'block';
      hoverBoxRef.current.innerHTML = text;
      positionHover(e);
    }
    function mouseOut(e) {
      //console.log('mouseOut', type);
      hoverStack.pop();
      if(!hoverStack.length)
        hoverBoxRef.current.style.display = 'none';
      else
        hoverBoxRef.current.innerHTML = hoverStack.at(-1).text;
    }
    switch(type) {
    case 'polygon':
      item.addListener('mousemove', mouseMove);
      item.addListener('mouseover', mouseOver);
      item.addListener('mouseout', mouseOut);
      getEventCoords = (e) => {
        return {
          x:e.domEvent.offsetX,
          y:e.domEvent.offsetY,
        };
      };
      break;
    case 'marker':
      item.addEventListener('mousemove', mouseMove);
      item.addEventListener('mouseover', mouseOver);
      item.addEventListener('mouseout', mouseOut);
      getEventCoords = (e) => {
        const mapBR = mapContainerInner.getBoundingClientRect();
        return {
          x:e.clientX-mapBR.x,
          y:e.clientY-mapBR.y,
        };
      };
      break;
    default:
      assert(false);
    }
  }
  function addPolygon(region) {
    assert(map);
    // eslint-disable-next-line
    const polygon = new google.maps.Polygon({
      map: map,
      paths: geometryToPath(region.geometry),
      strokeColor: '#F00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#F00',
      fillOpacity: 0.35,
      geodesic: true,
      gmpClickable: true,
    });
    polygon.addListener("click", () => {
      if(regionDeleteMode)
        deleteRegionRef.current(region);
    });
    makeHoverable('polygon', polygon, region.name);
    polygon.regionId = region.id;
    polygons.push(polygon);
    return polygon;
  }
  function tryFinalizeMapSetup() {
    if(!mapData || !map)
      return false;
    
    tryBoundsRefit();
    
    // create map polygons & tag places with the regions they're in (for search)
    trySetPolygons();
    updatePlaceRegions();
  }
  function trySetPolygons() {
    // prep for presence check
    polygons.forEach(p=>{
      delete p.keep;
    });
    
    // create
    mapData.regions.forEach(region=>{
      let polygon = polygons.find(p=>p.regionId===region.id);
      if(!!polygon) {
        if(polygon.map!==map) { // HACK - shouldn't be disassociating w map
          polygon.setMap(map);
        }
      }
      else {
        polygon = addPolygon(region);
      }
      polygon.keep = true;
    });
    
    // remove unused
    [...polygons].forEach(polygon=>{
      if(!polygon.keep) {
        polygon.setMap(null);
        arrayExtract(polygons, p=>p.regionId===polygon.regionId);
      }
    });
  }
  function setMapMarkers() {    
    // prep for marker check
    markers.forEach(marker=>{
      delete marker.keep;
    });

    // create new markers
    const bounds = new LatLngBounds();
    places.forEach(place=>{
      let marker = markers.find(m=>m.placeId===place.id);
      const position = getPlaceLL(place);
      if(!!marker) {
        if(marker.map!==map) { // HACK - shouldn't be disassociating w map
          marker.map = map;
        }
      }
      else {
        //console.log('made marker');
        marker = new AdvancedMarkerElement({
          map: map,
          position,
          gmpClickable: true,
        });
        marker.addEventListener("click", () => {
          selectPlaceInnerRef.current(place.id);
        });
        makeHoverable('marker', marker, place.name);
        marker.placeId = place.id;
        markers.push(marker);
        needBoundsRefit = true;
      }
      marker.keep = true;
      bounds.extend(position);
    });
        
    // remove unused markers
    [...markers].forEach(marker=>{
      if(!marker.keep) {
        marker.map = null;
        arrayExtract(markers, m=>m.placeId===marker.placeId);
        needBoundsRefit = true;
      }
    });

    // save bounds
    if(needBoundsRefit)
      needBoundsRefit = bounds;
  }
  
  // initialize and add the map
  useEffect(() => {
    if(!mapRef.current) {
      return; // we're not on a view that has the map
    }
    if(!mapContainerInner) {
      mapContainerInner = document.createElement('div');
      mapContainerInner.style.width = '100%';
      mapContainerInner.style.height = '100%';
    }
    const mapContainerOuter = mapRef.current;
    if(!mapContainerOuter.contains(mapContainerInner)) {
      mapContainerOuter.appendChild(mapContainerInner);
    }
    const isMapLoaded = !!map;
    
    async function initMap() {
      // eslint-disable-next-line
      Geometry = await google.maps.importLibrary("geometry");
      // eslint-disable-next-line
      const { Map } = await google.maps.importLibrary("maps");
      let libraries;
      // eslint-disable-next-line
      libraries = await google.maps.importLibrary("marker");
      AdvancedMarkerElement = libraries.AdvancedMarkerElement;
      PinElement = libraries.PinElement;
      // eslint-disable-next-line
      libraries = await google.maps.importLibrary("core");
      LatLngBounds = libraries.LatLngBounds;

      // create map
      map = new Map(mapContainerInner, {
        zoom: 4,
        center:{ lat: 39.2, lng: -97 },
        mapId: "DEMO_MAP_ID",
        clickableIcons: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        rotateControl: false,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      });
      mapControls.forEach(control=>{
        // eslint-disable-next-line
        addMapControl(google.maps.ControlPosition.RIGHT_CENTER, control);
      });

      map.addListener("click", (e)=>{
        mapClickRef.current(e);
      });
      console.log('loaded map', {mapDataLoaded:!!mapData});
      
      // if data loads first then map, we finalize here. depends on network speeds & tends to happen when switching back to map tab from other tabs
      if(mapData) {
        setMapMarkers();
        tryFinalizeMapSetup();
      }
    }
    
    //console.log('try load map?', {userLoaded:!!user, isMapLoaded, mapLoading});
    if(!isMapLoaded && !mapLoading && ((authed && user?.monthCost<500 && !!user?.subscription) || demoMode)) {
      mapLoading = true;
      initMap();

      if(!demoMode) {
        UserDataService.incMeterMapRefresh()
        .then(result => {
          // ignore result
        });
      }
    }
  }); // run after every render

  // move map out of dom before its destroyed
  useLayoutEffect(() => () => {
    if(mapContainerInner)
      document.body.appendChild(mapContainerInner); // can't removeChild, must append it somewhere else, or we lose all markers
  }, []); // runs before removal from dom
  
  // handle scroll position in list view & map bounds refit
  function tryBoundsRefit() {
    if(needBoundsRefit) {
      const bounds = needBoundsRefit;
      const mapContainerOuter = mapRef.current;
      console.log('did bounds refit', {numMarkers:markers.length, bounds, innerInOuter:mapContainerOuter?.contains(mapContainerInner), mciBounds:mapContainerInner.getBoundingClientRect()});
      map.fitBounds(bounds);
      needBoundsRefit = false;
    }
  }
  useEffect(() => {
    if(needScrollFix) {
      window.scrollTo({top:lastScrollPos, left:0, behavior: "instant"});
      lastScrollPos = null;
      needScrollFix = false;
    }
    tryBoundsRefit();
  }); // run after every render
  
  // custom map controls
  const mapControls = [
    {
      iconName:'pencil-square',
      iconAlt:'Create new map region',
      func:()=>{ regionBeginDrawRef.current() },
      topAdjustment:-10,
      type:'create',
    },
    {
      iconName:'x-square',
      iconAlt:'Delete a map region',
      func:()=>{ regionBeginDeleteRef.current() },
      topAdjustment:30,
      type:'delete',
    },
  ];
  function addMapControl(position, control) {
    const controlButton = document.createElement('button');

    // Set CSS for the control
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = '#666';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '16px';
    controlButton.style.lineHeight = '27px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';
    controlButton.style.position = 'absolute';
    controlButton.style.right = '10px';
    controlButton.style.top = `${control.topAdjustment}px`;
    controlButton.style.width = '40px';
    controlButton.style.height = '40px';

    controlButton.innerHTML = `<i class="bi bi-${control.iconName}" style="zoom:1.2"></i>`;
    controlButton.title = control.iconAlt;
    controlButton.type = 'button';

    // Setup the click event listeners
    controlButton.addEventListener('click', control.func);
    
    // Create the DIV to hold the control
    const controlDiv = document.createElement('div');
    // Append the control to the div
    controlDiv.appendChild(controlButton);
    controlDiv.type = control.type;

    map.controls[position].push(controlDiv);
    return controlDiv;
  }
  function getMapControlElt(type) {
    // eslint-disable-next-line
    return map.controls[google.maps.ControlPosition.RIGHT_CENTER].getArray().find(c=>c.type===type);
  }
  function setMapControlActive(type, isActive) {
    const control = getMapControlElt(type);
    control.style.filter = isActive ? 'invert(1)': '';
  }
  function showMapControls(show) {
    map.setOptions({
      zoomControl: show,
    });
    for(const control of mapControls) {
      const elt = getMapControlElt(control.type);
      elt.style.display = show ? 'block' : 'none';
    }
  }
  
  // create region
  function regionBeginDraw() {
    regionCancelDelete();
    setRegionDrawMode(true);
    clearNewRegionPolyline();
  }
  regionBeginDrawRef.current = regionBeginDraw;
  function setRegionDrawMode(on) {
    setMapControlActive('create', on);
    nameBoxShow(on);
  }
  function regionCancelDraw() {
    setRegionDrawMode(false);
  }
  function nameBoxShow(show) {
    const elt = newRegionNameBox.current;
    elt.classList.remove(show?'hide':'show');
    elt.classList.add(show?'show':'hide');
    elt.style.display = show?'block':'none';
  }
  function regionNameDone() {
    newRegionName = newRegionNameInput.current.value;
    newRegionNameInput.current.value = "";
    nameBoxShow(false);

    // eslint-disable-next-line
    newRegionPolyline = new google.maps.Polyline({
      strokeColor: "#F00",
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    newRegionPolyline.setMap(map);
  }
  function mapClick(e) {
    //console.log('mapClick', mapData);
    
    // cancel place box
    if(!!currentPlace)
      setPlace(null);
    
    // if we're drawing, place next point
    if(!!newRegionPolyline) {
      const path = newRegionPolyline.getPath();
      const eventLL = e.latLng;
      const eventP = pixelFromLatLng(eventLL);
      let lastProximityClose = false;
      for(let i=0; i<path.length && !lastProximityClose; ++i) {
        const pathPointP = pixelFromLatLng(path.getArray()[i]);
        const proximity = Math.hypot(eventP.x-pathPointP.x, eventP.y-pathPointP.y);
        lastProximityClose = proximity<20;
      }
      if(lastProximityClose) {
        finishRegion(path);
      }
      else {
        path.push(eventLL);
        function getNewRegionPointMarker() {
          const div = document.createElement('div');
          div.style.display = 'block';
          div.style.width = '6px';
          div.style.height = '6px';
          div.style.borderRadius = '50%';
          div.style.backgroundColor = 'red';
          div.style.transform = 'translate3d(0,50%,0)';
          return div;
        }
        newRegionMarkers.push(new AdvancedMarkerElement({
          map,
          position:eventLL,
          content:getNewRegionPointMarker(),
        }));
      }
    }
    
    // if not, cancel any region modes
    else {
      regionCancelDelete();
      regionCancelDraw();
    }
    
    e.stop();
  }
  mapClickRef.current = mapClick;
  function finishRegion(path) {
    const regionName = newRegionName;
    const region = {
      id:null,
      name:regionName,
      geometry:pathToGeometry(path),
    };
    const polygon = addPolygon(region);
    mapData.regions.push(region);
    updatePlaceRegions();
    processResult(getInstantResult());
    clearNewRegionPolyline();
    
    if(!demoMode) {
      UserDataService.addRegion(regionName, path.getArray())
      .then(result => {
        polygon.regionId = result.result.newRegion.id;
        processResult(result);
        //showAlert('Region deleted');
      });
    }
  }
  function clearNewRegionPolyline() {
    if(newRegionPolyline) {
      newRegionPolyline.setMap(null);
      newRegionPolyline = null;
      newRegionName = null;
      newRegionMarkers.forEach(c=>{
        c.map = null;
      });
      newRegionMarkers = [];
      setMapControlActive('create', false);
    }
  }
  function pixelFromLatLng(latLng) {
    let projection = map.getProjection();
    let bounds = map.getBounds();
    let topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
    let bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
    let scale = Math.pow(2, map.getZoom());
    let worldPoint = projection.fromLatLngToPoint(latLng);
    return {
      x:Math.floor((worldPoint.x - bottomLeft.x) * scale),
      y:Math.floor((worldPoint.y - topRight.y) * scale)
    };
  }
  function geometryToPath(geometry) {
    return geometry.coordinates[0].map(pt=>({
      lat:pt[0],
      lng:pt[1]
    }));
  }
  function pathToGeometry(path) {
    return {
      type:'Polygon',
      coordinates:[
        path.getArray().map(pt=>([
          pt.lat(),
          pt.lng()
        ]))
      ]
    };
  }
  
  // delete region
  function regionBeginDelete() {
    clearNewRegionPolyline();
    regionCancelDraw();
    setRegionDeleteMode(!regionDeleteMode); // toggle the mode
  }
  regionBeginDeleteRef.current = regionBeginDelete;
  function regionCancelDelete() {
    setRegionDeleteMode(false);
  }
  function setRegionDeleteMode(newVal) {
    regionDeleteMode = newVal;
    setMapControlActive('delete', newVal);
  }
  function deleteRegion(region) {
    regionCancelDelete();
    
    let polygon;
    if(!region.id) {
      region.id = findPolygonFromRegionGeometry(region).regionId;
    }
    assert(region.id);
    polygon = arrayExtract(polygons, p=>p.regionId===region.id)[0];
    polygon.setMap(null);
    arrayExtract(mapData.regions, r=>r.id===region.id);
    processResult(getInstantResult());
    updatePlaceRegions();
    
    if(!demoMode) {
      UserDataService.deleteRegion(region.id)
      .then(result => {
        processResult(result);
        //showAlert('Region deleted');
      });
    }
  }
  deleteRegionRef.current = deleteRegion;
  function findPolygonFromRegionGeometry(region) {
    return polygons.find(p=>objectEquals(pathToGeometry(p.getPath()), region.geometry));
  }
    
  // place
  function setPlace(id) {
    setCurrentPlace(id);
    if(!!currentPlace && !id) {
      showMapControls(true);
    }
    else if(!currentPlace && !!id) {
      showMapControls(false);
    }
  }
  function selectPlaceInner(id) {
    setPlace(id);
    lastScrollPos = window.scrollY;
  }
  selectPlaceInnerRef.current = selectPlaceInner;
  function selectPlace(id) {
    return ()=>{
      selectPlaceInner(id);
    };
  }
  function clearLastHighlightedPlace() {
    if(lastHighlightedPlace) {
      const lastMarker = markers.find(m=>m.placeId===lastHighlightedPlace);
      lastHighlightedPlace = null;
      if(lastMarker)
        lastMarker.content = (new PinElement()).element;
    }
  }
  function hoverPlace(id) {
    return ()=>{
      clearLastHighlightedPlace();
      lastHighlightedPlace = id;
      const marker = markers.find(m=>m.placeId===id);
      const lightBlue = '#4051D8';
      const darkBlue = '#1F29A4';
      marker.content = (new PinElement({
        background:lightBlue,
        glyphColor:darkBlue,
        borderColor:darkBlue,
      })).element;
    };
  }
  function unHoverPlace() {
    clearLastHighlightedPlace();
  }
  function placeBack() {
    setPlace(null);
    needScrollFix = true;
  }
  function openTabs() {
    places.forEach(place=>{
      if(!isPlaceHidden(place))
        window.open(place.url, '_blank');
    });
  }

  // loading screen
  if(!demoMode) {
    let screen;
    if(!authed || !user) {
      screen = <Loading />;
    }
    else if(!mapData) {
      if(!!user.subscription) {
        screen = <Loading />;
      }
      else {
        screen = <NeedSubscribe user={user} />;
      }
    }
    if(screen) {
      return <div class="pt-5 mx-5">{screen}</div>;
    }
  }
  
  // subscription screen
  if(authed && !!user && !user.subscription) {
    return (
      <div class="text-center mt-5">
        <div class="fs-3">
          You must subscribe to use Map Tools!
        </div>
        <div class="mt-4 mx-auto" style={{display:'inline-block'}}>
          <Link to={getSubscriptionLink(user)}>
            <button class="btn btn-primary signup-btn w-100">Subscribe to Map Tools</button>
          </Link>
        </div>
      </div>
    );
  }

  // search & setting current place
  const view = header.view;
  if(mapData) {
    // if map loads first then data, we finalize here
    tryFinalizeMapSetup();
    
    // if new search, clear any hidden places
    const searchText = header.searchText;
    if(searchText !== lastSearch) {
      lastSearch = searchText;
    }

    // do search to filter mapData.places down to places
    places = mapData.places;
    if(!!searchText) {
      const searches = searchText.split(' ').map(searchWord=>{
        function newSearch(type, data, isExcluded) {
          assert(type!==undefined && data!==undefined && isExcluded!==undefined);
          return { type, data, isExcluded };
        }
        let isMinus = searchWord.charAt(0)==='-';
        if(isMinus) {
          searchWord = searchWord.slice(1);
          if(!searchWord)
            return null; // return null search for empty negation terms
        }
        searchWord = searchWord.replaceAll('-', ' ');
        let isColon = searchWord.split(':').length===2 && searchWord.split('>').length===1;
        let isGT = searchWord.split('>').length===2 && searchWord.split(':').length===1;
        searchWord = searchWord.toLowerCase();
        
        let search;
        if(isColon) {
          const [type, data] = searchWord.split(":");
          search = null;
          switch(type) {
          case 'list':
          case 'name':
          case 'type':
          case 'city':
          case 'region':
          case 'tag':
            return newSearch(type, data, isMinus);
          default:
            // ignore invalid search types
          }
          return search;
        }
        else if(isGT) {
          const [type, data] = searchWord.split(">");
          search = null;
          switch(type) {
          case 'open':
            if(data.length===4 && parseInt(data.slice(0,2))<24 && parseInt(data.slice(2))<60) {
              return newSearch(type, parseInt(data), isMinus);
            }
            break;
          case 'rating':
            return newSearch(type, parseFloat(data), isMinus);
          case 'numratings':
            return newSearch(type, parseInt(data), isMinus);
          case 'visit':
            return newSearch(type, parseInt(data), isMinus);
          default:
            // ignore invalid search types
          }
          return search;
        }
        else {
          return newSearch('text', searchWord, isMinus);
        }
      });
      places = mapData.places.filter(place=>{
        let ok = true;
        let strings = [];
        strings.push(place.name.toLowerCase());
        strings.push(getPlaceTypesDisplay(place).toLowerCase());
        strings.push(getPlacePrimaryTypeDisplay(place).toLowerCase());
        const listNames = getPlaceListNames(place).map(n=>n.toLowerCase());
        strings = strings.concat(listNames);
        strings = strings.concat(getPlaceTextTags(place).map(t=>t.name));
        strings = strings.concat(getPlaceRegionNames(place));
        strings.push(place.neighborhood?.toLowerCase());
        strings.push(place.city?.toLowerCase());
        strings.push(place.state?.toLowerCase());
        strings.push(place.country?.toLowerCase());
        strings = strings.filter(s=>!!s);
        for(let i=0; i<searches.length && ok; ++i) {
          const search = searches[i];
          if(!search)
            continue;
          switch(search.type) {
          case 'text':
            ok = !!strings.find(s=>s.includes(search.data));
            break;
          case 'list':
            ok = !!listNames.find(ln=>ln.includes(search.data));
            break;
          case 'name':
            ok = place.name.toLowerCase().includes(search.data);
            break;
          case 'city':
            ok = place.city?.toLowerCase().includes(search.data);
            break;
          case 'type':
            ok = getPlacePrimaryTypeDisplay(place).toLowerCase().includes(search.data);
            break;
          case 'region':
            ok = getPlaceRegionNames(place).find(n=>n.includes(search.data));
            break;
          case 'tag':
            ok = !!getPlaceTextTags(place).map(t=>t.name).find(t=>t.includes(search.data));
            break;
          case 'rating':
            ok = place.rating>=search.data;
            break;
          case 'numratings':
            ok = place.numRatings>=search.data;
            break;
          case 'visit':
            const lastVisit = Math.max(...getPlaceVisits(place).map(v=>v.time));
            ok = !lastVisit || (Date.now()-1000*3600*24*search.data)>lastVisit;
            break;
          case 'open':
            const day = (new Date()).getDay();
            const openHours = place.openTimes[day];
            if(!openHours) {
              ok = true;
            }
            else if(openHours.length===2) {
              const open = parseInt(openHours[0].replace(':', ''));
              const close = parseInt(openHours[1].replace(':', ''));
              if(open<close) {
                ok = search.data>=open && search.data<=close;
              }
              else {
                ok = search.data>=open || search.data<=close;
              }
            } 
            else {
              ok = false;
            }
            break;
          default:
            // ignore invalid search types
          }
          if(search.isExcluded)
            ok = !ok;
        }
        return ok;
      });
      //console.log('built place list', map, places);
    }
    header.updatePlaces(places, hiddenPlaces);
    header.setNumLists(mapData.lists.length);
        
    // update map markers based on search results
    if(map && view==='map') {
      setMapMarkers();
    }
    
    // set place if necessary
    if(currentPlace) {
      place = mapData.places.find(p=>p.id===currentPlace);
    }
    else {
      place = null;
    }
  }
  function searchTogglePlaceVisible(place, doPlaceBack) {
    return (e)=>{
      // flip visibility
      if(isPlaceHidden(place))
        arrayRemoveValue(hiddenPlaces, place.id);
      else
        hiddenPlaces.push(place.id);
      
      // update header so next export is correct
      header.updatePlaces(places, hiddenPlaces);
      
      // must force update bc we changed a non-state var
      forceUpdate();
      
      // if we're on a place card, close it
      if(doPlaceBack)
        placeBack();
      
      // don't click list item
      e.stopPropagation();
    };
  }
  function isPlaceHidden(place) {
    if(!place)
      return false;
    return hiddenPlaces.includes(place.id);
  }
  function clearAllHidden() {
    hiddenPlaces = [];
    forceUpdate();
  }
  
  // tags
  let uniqueTags = [];
  if(mapData) {
    mapData.places.forEach(p=>{
      if(p.tags)
        uniqueTags = uniqueTags.concat(getPlaceTextTags(p).map(t=>t.name));
    });
    uniqueTags = arrayDedupe(uniqueTags);
  }
  function getInstantResult() {
    return {
      result:{
        newMapData:mapData
      }
    };
  }
  function addTag(name) {
    // make instant change
    place.tags ??= [];
    place.tags.push({
      userId,
      type:'text',
      name
    });
    processResult(getInstantResult());
    
    // send change to server
    if(!demoMode) {
      UserDataService.addTag(currentPlace, 'text', name)
      .then(result => {
        processResult(result);
        //showAlert('Tag added');
      });
    }
  }
  function deleteTag(tag) {
    return ()=>{
      // make instant change
      arrayExtract(place.tags, t=>t.userId===userId && t.type==='text' && t.name===tag.name);
      processResult(getInstantResult());
    
      // send change to server
      if(!demoMode) {
        UserDataService.deleteTag(currentPlace, 'text', tag.name)
        .then(result => {
          processResult(result);
          //showAlert('Tag deleted');
        });
      }
    };
  }
  function getUserNameTag(userId) {
    return <span class="fw-light opacity-50">({mapData.otherUsers.find(u=>u.id===userId).fullName.split(' ')[0]})</span>;
  }
  
  // visits
  function logVisit(visitType) {
    return () => {
      let timestamp = Date.now();
      switch(visitType) {
      case '-1w':
        timestamp -= 1000*3600*24*7;
        break;
      case '-1m':
        timestamp -= 1000*3600*24*365.25/12;
        break;
      case '-1q':
        timestamp -= 1000*3600*24*365.25/4;
        break;
      case '-1y':
        timestamp -= 1000*3600*24*365.25;
        break;
      default:
        // should only get here if we chose today
      }

      // make instant change
      place.tags ??= [];
      place.tags.push({
        userId,
        type:'visitTimestamp',
        time:timestamp
      });
      processResult(getInstantResult());
    
      // send change to server
      if(!demoMode) {
        UserDataService.addTag(currentPlace, 'visitTimestamp', timestamp.toString())
        .then(result => {
          processResult(result);
          //showAlert('Visit added');
        });
      }
    };
  }
  function deleteVisit(visit) {
    return ()=>{
      // make instant change
      arrayExtract(place.tags, t=>t.userId===userId && t.type==='visitTimestamp' && t.name===visit.time.toString());
      processResult(getInstantResult());
    
      // send change to server
      if(!demoMode) {
        UserDataService.deleteTag(currentPlace, 'visitTimestamp', visit.time.toString())
        .then(result => {
          processResult(result);
          //showAlert('Visit deleted');
        });
      }
    };
  }
  
  // render helpers
  function renderPlaceList(places) {
    return (
      <div class="list-group rounded-0">
        { places.map((place, i)=>
        <div class={`list-group-item list-group-item-action text-bg-secondary border-0 border-bottom`} style={{userSelect:'none', cursor:'pointer', zIndex:0}} key={i} onClick={selectPlace(place.id)} onMouseEnter={hoverPlace(place.id)} onMouseLeave={unHoverPlace}>
          <div class="fs-5">{place.name}</div>
          <div class="fs-6 opacity-50">{getPlacePrimaryTypeDisplay(place)}</div>
          <div class="position-absolute end-0 top-50 translate-middle-y me-3">
            <i class={`bi bi-eye${isPlaceHidden(place)?'':'-slash'} btn-icon fs-4`} style={{zIndex:1}} onClick={searchTogglePlaceVisible(place)}></i>
          </div>
        </div>
        )}
      </div>
    );
  }
  function changePlaceView(newPlaceView) {
    return ()=>{
      setPlaceView(newPlaceView);
    };
  }
  function renderPlaceNavLink(name) {
    return (
      <li class="nav-item">
        <span class={`nav-link text-dark pb-1 ${placeView===name?'active':'opacity-75'}`} onClick={changePlaceView(name)} style={{cursor:'pointer'}}>{capitalizeFirstLetter(name)}</span>
      </li>
    );
  }

  // render
  const showSearchHints = header.showSearchHints;
  const anyHiddenPlaces = !!hiddenPlaces.length;
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  console.log('rendering dash', {view, showSearchHints, place, hiddenPlaces, markers});
  return (
    <>
      <div class="h-100" style={{overflowY:(view==='list'?'scroll':'hidden')}}>
    
        { view==='map' && /* map view */
          <>
          <div style={{position:'relative', left:0, height:'100%', width:'100%', top:0}} ref={mapRef} />
          <div style={{border:'1px solid #888', backgroundColor:'rgba(0,0,0,0.6)', display:'none', position:'absolute', padding:'1px 4px', color:'white', fontSize:11}} ref={hoverBoxRef} />
          <div class="popover bs-popover-auto fade hide name-box" style={{position:'fixed', margin:0, display:'none', right:'58px', top:'calc(50% + 74px)'}} data-popper-placement="left" ref={newRegionNameBox}>
            <div class="popover-arrow" style={{position:'absolute', transform:'translate3d(0px, 50%, 0px)', top:0}}></div>
            <div class="popover-body input-group p-25 p-sm-3">
              <input type="text" class="form-control" placeholder="Name..." ref={newRegionNameInput} />
              <button class="btn btn-primary signup-btn" onClick={regionNameDone}>Start drawing</button>
            </div>
          </div>
          </>
        }
        
        { view==='list' && /* list view */
          <>
          { renderPlaceList(places.filter(p=>!isPlaceHidden(p))) }
          { (places.length-hiddenPlaces.length)>0 &&
            <div class="my-4 w-100 text-center fs-4">
              <button type="button" class="btn btn-primary" onClick={openTabs}>Open all in new tabs</button>
              { anyHiddenPlaces &&
                <button type="button" class="btn btn-outline-primary ms-3" onClick={clearAllHidden}>Clear hidden</button>
              }
            </div>
          }
          { anyHiddenPlaces &&
            <>
            <div class="mt-4 mb-2 w-100 text-center fs-4 text-muted">
              Hidden
            </div>
            <div class="opacity-25">
              { renderPlaceList(places.filter(p=>isPlaceHidden(p))) }
            </div>
            </>
          }
          { !mapData?.places.length &&
            <div class="my-4 w-100 text-center fs-4">
              <div class="text-muted mb-3">
                You haven&apos;t imported your Google Maps saved lists yet!
              </div>
              <Link to="/lists"><button class="btn btn-primary signup-btn">Import lists</button></Link>
            </div>
          }
          { !!mapData?.places.length && !places.length && 
            <div class="my-4 w-100 text-center fs-4 text-muted">
              No results found
            </div>
          }
          </>
        }
      </div>
        
      { /* search hints */ }
      <div class={`position-absolute top-0 w-100 px-3 ${showSearchHints?'h-50 opacity-100':''}`} style={{height:0, opacity:0, transition:'all 0.5s', overflow:'hidden'}}>
        <div class="rounded-bottom-3 h-100 bg-white" style={{overflow:'hidden scroll'}}>
          <div class="p-3 p-sm-4 row">
            <div class="col-6">
              <p class="mb-2 fs-5">How to search</p>
              <p class="text-muted">Plain filter terms will match all fields. Terms with a "-" in front will be negated.</p>
              <p class="text-muted">Filters can be combined and partial matches are accepted, e.g. <strong>resta list:mex open&gt;2200 -tijuana</strong> would match restaurants in a list called "Mexico" that are open after 10pm and not located in Tijuana</p>      
            </div>
            <div class="col-6">
              <p class="mb-2 fs-5">Special filter terms</p>
              <ul class="text-muted ps-3">
                <li><strong>name:X</strong> for place names</li>
                <li><strong>list:X</strong> for list names</li>
                <li><strong>type:X</strong> for place types</li>
                <li><strong>city:X</strong> for city names</li>
                <li><strong>region:X</strong> for region names</li>
                <li><strong>tag:X</strong> for tags</li>
                <li><strong>rating&gt;X.X</strong> for places with ratings &gt; X or X.X</li>
                <li><strong>numratings&gt;X</strong> for places with # of ratings &gt; X</li>
                <li><strong>open&gt;HHMM</strong> for places that are open today until or after HH:MM in 24-hr time</li>
                <li><strong>visit&gt;X</strong> for places you have NOT visited within the last X days</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      { /* place */ }
      <div class={`position-absolute bottom-0 w-100 px-3 ${!!place?'h-50 opacity-100':''}`} style={{height:0, opacity:0, transition:'all 0.5s'}}>
        <div class="rounded-top-3 h-100 bg-white" style={{overflowY:'scroll'}}>

          { /* photo */ }
          { !!place?.photoUrl &&
            <div class="w-100" style={{
              height:'15vh',
              backgroundImage:`url(${place.photoUrl})`,
              backgroundSize:'cover',
              backgroundPosition:'center',
              backgroundRepeat:'no-repeat',
            }} />
          }

          <div class="p-3 p-sm-4">
      
            { /* header */ }
            <div class="d-flex justify-content-center mb-2">
              <div class="flex-grow-1">
                <div class="fs-2">
                  <strong>{capitalizeFirstLetter(place?.name)}</strong>
                </div>
                { !!place?.primaryType &&
                  <div class="text-muted">
                    {getPlacePrimaryTypeDisplay(place)}
                  </div>
                }
                { place?.businessStatus && place?.businessStatus!=='OPERATIONAL' &&
                  <p class="text-danger">
                    <strong>{capitalizeFirstLetter(place?.businessStatus.toLowerCase())}</strong>
                  </p>
                }
              </div>
              <div class="flex-grow-0 d-inline-flex flex-column flex-sm-row">
                <div class="order-2 order-sm-1">
                  <Link to={place?.url} target="_" class="btn btn-outline-primary" style={{whiteSpace:'nowrap'}}>
                    <span class="d-none d-sm-block">Open in Google Maps</span>
                    <span class="d-block d-sm-none">Open in Maps</span>
                  </Link>
                </div>
                <div class="order-1 order-sm-2 text-end mb-1" style={{whiteSpace:'nowrap'}}>
                  <i class={`bi bi-eye${isPlaceHidden(place)?'':'-slash'} btn-icon fs-4 ms-3`} onClick={searchTogglePlaceVisible(place, true)}></i>
                  <i class="bi bi-x-lg btn-icon fs-4 ms-3" onClick={placeBack}></i>
                </div>
              </div>
            </div>

            { /* loading */ }
            { place?.loadStatus!=='loaded' ?
              <>
              <p>Loading...</p>
              <button class="btn btn-primary" onClick={refreshData}>Refresh</button>
              </>
            :
              <>
              { /* rating */ }
              { !!place?.numRatings &&
                <div class="mb-3">
                  <span>
                    <i class="bi bi-star-fill" /> <strong>{place.rating}</strong> stars
                  </span>
                  <span class="ms-3">
                    <i class="bi bi-people" /> <strong>{place.numRatings}</strong> reviews
                  </span>
                </div>
              }
    
              { /* nav */ }
              <ul class="nav nav-underline mb-3">
                { renderPlaceNavLink('info') }
                { !!place?.openTimes?.length ? renderPlaceNavLink('hours') : '' }
                { !!place?.photoUrl ? renderPlaceNavLink('photos') : '' }
                { renderPlaceNavLink('tags') }
                { renderPlaceNavLink('visits') }
              </ul>

              { /* tabs */ }
              <div>
                { placeView==='info' &&
                  <>
                  <p>
                    <span>In Lists:</span> <strong>{getPlaceListNames(place).join(', ')}</strong>
                  </p>
                  { !!place?.description &&
                    <p>{place.description}</p>
                  }
                  <p>
                    { !!place?.neighborhood &&
                      <div><span>Neighborhood:</span> <strong>{place.neighborhood.split(' , ')[0]}</strong></div>
                    }
                    <div><span>City:</span> <strong>{place?.city?.split(' , ')[0]}</strong></div>
                    <div><span>State:</span> <strong>{place?.state?.split(' , ')[0]}</strong></div>
                    <div><span>Country:</span> <strong>{place?.country?.split(' , ')[0]}</strong></div>
                  </p>
                  </>
                }
                { placeView==='hours' &&
                  <>
                    { !!place?.openTimes?.length ?
                      <>
                      { dayNames.map((dayName, i)=>
                        <div>{dayName}: <strong>{place.openTimes[i].length!==2?'Closed':`${place.openTimes[i][0]} - ${place.openTimes[i][1]}`}</strong></div>
                      )}
                      </>
                    :
                      <p>No hours listed</p>
                    }
                  </>
                }
                { placeView==='photos' &&
                  <>
                    { !!place?.photoUrl ?
                      <img src={place.photoUrl} class="w-100" alt="" />
                    :
                      <p>No photos</p>
                    }
                  </>
                }
                { placeView==='tags' &&
                  <>
                  { getPlaceTextTags(place).map((tag, index)=>
                    <span class="badge rounded-pill text-bg-success fs-5 me-2 mb-2" key={index}>
                      {capitalizeFirstLetter(tag.name)}&nbsp;
                      { tag.userId===userId ?
                        <i class="bi bi-x icon-btn" onClick={deleteTag(tag)} style={{cursor:'pointer'}}></i>
                      :
                        getUserNameTag(tag.userId)
                      }
                    </span>
                  ) }
                  <div class={`d-flex align-items-center ${!!getPlaceTextTags(place).length?'mt-2':''}`}>
                    <div style={{whiteSpace:'nowrap'}}>Add tag</div>
                    <div class="ms-3 flex-grow-1">
                      <Combobox tags={uniqueTags} onSubmit={addTag} />
                    </div>
                  </div>
                  </>
                }
                { placeView==='visits' &&
                  <>
                  { getPlaceVisits(place).map((visit, index)=>(
                    <span class="badge rounded-pill text-bg-success fs-5 me-2 mb-2" key={index}>
                      {timeAgo.format(visit.time)}&nbsp;
                      { visit.userId===userId ?
                        <i class="bi bi-x icon-btn" onClick={deleteVisit(visit)} style={{cursor:'pointer'}}></i>
                      :
                        getUserNameTag(visit.userId)
                      }
                    </span>
                  )) }
                  <div class={`d-flex align-items-center ${!!getPlaceVisits(place).length?'mt-2':''}`}>
                    <div style={{whiteSpace:'nowrap'}}>Add visit</div>
                    <div class="ms-3 flex-grow-1">
                      <div class="btn-group flex-wrap flex-sm-nowrap">
                        <button type="button" class="btn btn-primary mb-1 mb-sm-0" style={{borderColor:'white'}} onClick={logVisit('today')}>Today</button>
                        <button type="button" class="btn btn-primary mb-1 mb-sm-0" style={{borderColor:'white'}} onClick={logVisit('-1w')}>-1 week</button>
                        <button type="button" class="btn btn-primary mb-1 mb-sm-0" style={{borderColor:'white'}} onClick={logVisit('-1m')}>-1 month</button>
                        <button type="button" class="btn btn-primary mb-1 mb-sm-0" style={{borderColor:'white'}} onClick={logVisit('-1q')}>-1 quarter</button>
                        <button type="button" class="btn btn-primary mb-1 mb-sm-0" style={{borderColor:'white'}} onClick={logVisit('-1y')}>-1 year</button>
                      </div>
                    </div>
                  </div>
                  </>
                }
              </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}