import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import useAuth from "../hooks/auth.hook";

export default function Login(props) {
  const navigate = useNavigate();
  const { signin, authed } = useAuth();
  
  useEffect(() => {
    if(authed) {
      navigate('/');
    }
  }); // run every render
  
  function handleLogin(e) {
    console.log('login start');
    e.preventDefault();
    function getClientTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    signin(getClientTimezone());
  }
  
  const signupMode = props.mode==='signup';

  function renderLoginForm() {
    return (
    <main class={`form-signin w-100 m-auto text-center`}>
    <form>
      <h1 class="h3 mb-4 fw-light text-muted">
        Welcome to Map Tools!
      </h1>

      <button class="btn btn-lg btn-primary" onClick={handleLogin}>
        <i class="bi bi-google me-3"></i> Sign {signupMode?'up':'in'} with Google
      </button>
    </form>
    </main>
    );
  }

  return (
    <>
      { signupMode ?
        <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center">
          <div class="mb-sm-45">
            { renderLoginForm() }
          </div>
          <div class="text-center mt-5 mt-sm-0 ms-sm-6">
            <div class="card rounded-3 shadow-sm">
              <div class="card-header py-3 text-light" style={{backgroundColor:'#ccc'}}>
                <h4 class="my-0 fw-normal">Pro</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$10<small class="text-muted fw-light">/mo</small></h1>
                <div class="text-center">
                  <ul class="list-unstyled mt-3 mb-4 text-start" style={{display:'inline-block'}}>
                    <li>Unlimited Google Maps saved lists</li>
                    <li>+200 saved places per month</li>
                    <li>Instant search, pro filters</li>
                    <li>Unlimited tags, visits, and regions</li>
                    <li>Dedicated support from founder</li>
                    <li>Custom feature requests</li>
                    <li>...and more!</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      :
        <>
        { renderLoginForm() }
        </>
      }
    </>
  );
}