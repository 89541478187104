import { useNavigate } from "react-router-dom";

import Loading from "./loading.component";

import UserDataService from "../services/user.service";

import { getSubscriptionLink, assert } from '../common/utility';

import useAuth from "../hooks/auth.hook";
import useData from "../hooks/data.hook";

export default function Account(props) {
  const { authed, logout } = useAuth();
  const { user } = useData('user', {page:'account', authed});
  const navigate = useNavigate();
    
  //console.log('Account - render');
    
  function handleLogout() {
    logout().then((success) => {
      if(success)
        navigate('/');
    });
  }
  function toggleSubscription(e) {
    e.preventDefault();
    switch(user.subscription) {
    case '':
      window.location = getSubscriptionLink(user);
      break;
    case 'pendingCancel':
      UserDataService.reactivateSubscription()
      .then(result => {
        window.location = '/account';
      });
      break;
    case 'active':
      UserDataService.cancelSubscription()
      .then(result => {
        window.location = '/account';
      });
      break;
    default:
      assert(false);
    }
  }
  function refreshData() {
    window.location.reload();
  }

  if(!user) {
    return (<Loading />);
  }
  return (
    <>
      <div class="p-425 p-sm-5 bg-light rounded-3">
        <h1 class="mb-4">Account Settings</h1>

        <h3 class="mb-2 mb-sm-4 text-muted fw-normal">User information</h3>
        <form>
          <div class="row mb-3">
            <label for="inputFName" class="col-sm-2 col-form-label">Name</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="inputFName" value={user.fullName} disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-10">
              <input type="email" class="form-control" id="inputEmail" value={user.email} disabled />
            </div>
          </div>
          <div class="text-end">
            <button class="btn btn-outline-danger px-4 me-3" onClick={handleLogout}>Log out</button>
            <button class="btn btn-primary px-4" disabled>Update</button>
          </div>
        </form>

        <h3 class="mb-2 mb-sm-4 text-muted fw-normal mt-4">Data</h3>
        <button class="btn btn-primary signup-btn px-4" onClick={refreshData}>Refresh place data</button>

        <h3 class="mb-2 mb-sm-4 text-muted fw-normal mt-4">Subscription</h3>
        <form>
          <div class="row mb-3">
            <div class="col-sm-2 col-form-label">Status</div>
            <div class="col-sm-10" style={{display:'flex'}}>
              <div class="my-auto fw-bold">
                { !!user.subscription ?
                <><i class="bi bi-check-circle-fill text-success"></i> Active {user.subscription==='pendingCancel'?'(cancels at end of current period)':''}</>
                :
                <><i class="bi bi-x-circle-fill text-danger"></i> Inactive</>
                }
              </div>
            </div>
          </div>
          <div class="mt-2 text-start">
            { !user.subscription &&
              <button class="btn btn-primary signup-btn px-4" onClick={toggleSubscription}>Subscribe</button>
            }
            { user?.subscription==='pendingCancel' &&
              <button class="btn btn-primary signup-btn px-4" onClick={toggleSubscription}>Re-activate subscription</button>
            }
            { user?.subscription==='active' &&
              <button class="btn btn-outline-secondary px-4" onClick={toggleSubscription}>Cancel subscription</button>
            }
          </div>
        </form>
      </div>
    </>
  );
}