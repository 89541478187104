import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";

import FeedbackService from "../services/feedback.service";

import useTimer from "../hooks/timer.hook";

export default function Feedback() {
  const page = useLocation();
  const timer = useTimer();

  const [sayThanks, setSayThanks] = useState(false);  
  const [submitting, setSubmitting] = useState(false);
  const inputBoxRef = useRef();
  const [feedback, setFeedback] = useState('');

  // actions
  function handleChange(e) {
    setFeedback(e.target.value);
  }
  function submitFeedback() {
    setSubmitting(true);
    const data = {
      text:feedback,
      page:page.pathname,
    };
    FeedbackService.submitFeedback(data)
    .then(() => {
      setSubmitting(false);
      setFeedback('');
      inputBoxRef.current.value = '';
      setSayThanks(true);
      timer.fn(() => {
        setSayThanks(false);
      }, 3000);
    });
  }

  // render
  return (
    <div class="p-425 p-sm-5 bg-light rounded-3">
      <h1 class="mb-4">
        Feedback
      </h1>
      <div class="p-4 p-sm-5 bg-white rounded-3 mb-3">
        <div class="mb-4 fs-6 fs-sm-4 text-muted">Have any ideas, feedback, questions, or problems? We&apos;d love to hear from you!</div>
        <textarea class="form-control mb-4" rows="3" disabled={submitting} onChange={handleChange} ref={inputBoxRef}></textarea>
        <button class="btn btn-primary px-4" onClick={submitFeedback} disabled={submitting || !feedback}>Submit</button>
        { sayThanks &&
          <div class="text-success ms-3" style={{display:'inline-block'}}>
            <span style={{position:'relative', top:3}}>Thank you</span>
          </div>
        }
      </div>
    </div>
  );
}

