import { useRef, useState } from "react";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";

import '@popperjs/core/dist/esm/popper.js'
//import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css"; // custom boostrap css
import "bootstrap-icons/font/bootstrap-icons.css"
//import 'bootstrap/dist/js/bootstrap.min.js'

import './App.css';

import Login from "./components/login.component";
import LandingStandalone from "./components/landingStandalone.component";
import Loading from "./components/loading.component";
import Account from "./components/account.component";
import Dashboard from "./components/dashboard.component";
import Lists from "./components/lists.component";
import Tags from "./components/tags.component";
import Feedback from "./components/feedback.component";

import useAuth from "./hooks/auth.hook";
import useData from "./hooks/data.hook";
import { useHeader, HeaderContext } from "./hooks/header.hook";

import { isAppStandalone, hideCollapse } from './common/utility';
import ENV from "./common/config.env";

if(ENV!=='dev') {
  console.log = ()=>{};
}

function Header() {
  const { authed } = useAuth();
  const { user } = useData('user', {page:'header', authed});
  const location = useLocation();
  const currLocation = location.pathname;  
  const header = useHeader();
  
  const menuRef = useRef();
  const alertRef = useRef();
  
  const onLogin = currLocation==='/signin' || currLocation==='/signup';
  const onDashboard = currLocation==='/dashboard' || currLocation==='/';
  const onLists = currLocation==='/lists';

  // helpers
  function navLink({link, text, isNav=true, classes, activeClasses, currLocation}) {
    classes ??= '';
    if(currLocation?.startsWith(link) && (link==='/'?link===currLocation:true)) { // HACK - will break if links start w same letters
      classes = activeClasses ?? `${classes} fw-semibold text-bg-light active`;
    }
    function onNavClick() {
      hideCollapse(menuRef);
    }
    return (<li class="nav-item"><Link to={link} class={`nav-link ${classes?classes:''}`} target={link.startsWith('http')?'_blank':''} onClick={onNavClick}>{text}</Link></li>);
  }
  
  // render
  const classes = 'text-bg-secondary fw-light px-3 ';
  const isSearching = !!header.searchText;
  const hasLists = !authed || (!!user?.subscription && header.numLists>0);
  return (
    <header class="navbar navbar-collapse justify-content-center p-25 p-sm-3 px-3 px-md-4 text-bg-dark flex-grow-0" style={{flexBasis:'unset'}}>
      <Link to={authed?'/dashboard':'/'} class={`header-logo d-flex align-items-center me-sm-4 text-bg-dark text-decoration-none ${onLogin?'flex-grow-1':''} ${isSearching?'searching':''}`}>
        <img src="icons/android-chrome-192x192.png" alt="" class={`bi bi-translate fs-2`} style={{width:'10vw'}} />
        { !authed &&
          <span class="fs-4 ms-3 fw-medium d-none d-sm-block">Map Tools</span>            
        }
        { !user?.subscription &&
          <div class="demo-badge position-absolute top-100 start-0">
            <span class="badge text-bg-primary">Demo</span>
          </div>
        }
      </Link>

      { !onLogin &&
        <>
        <div class="flex-grow-1 me-sm-3 mx-1">
          { hasLists ?
            <div class="input-group" style={{transition:'all 0.5s', width:onDashboard?'100%':'0%', opacity:onDashboard?1:0}}>
              <input type="text" class="form-control" style={{width:1}} placeholder="Search..." onChange={header.onSearchChange} value={header.searchText} spellCheck="false" autoCapitalize="off" autoCorrect="off" />
              { isSearching &&
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={header.clearSearch}>
                  <i class="bi bi-x btn-icon"></i>
                </button>
              }
            </div>
          : (!!user?.subscription && header.numLists===0 && !onLists) ?
            <div class="text-center">
              <Link to="/lists">
                <button class="btn btn-primary signup-btn">Add your Google places</button>
              </Link>
            </div>
          : ''
          }
        </div>
        { (onDashboard && isSearching) ?
          <>
            <div class="me-sm-2 mx-1 mx-sm-0 px-2">
              <i class={`bi bi-${header.view==='map' ? 'journal' : 'map'} btn-icon btn-icon-lg fs-4`} onClick={header.toggleView}></i>
            </div>
            <div class="me-2 ps-2">
              <i class={`bi bi-box-arrow-up btn-icon btn-icon-lg fs-4`} onClick={header.doExport(alertRef)}></i>
            </div>
          </>
        :
          <>
          { hasLists &&
            <div class="me-sm-1 me-0 ms-1 ms-sm-0 px-2 ps-1" style={{transition:'all 0.5s', opacity:onDashboard?1:0}}>
              <i class={`bi bi-question${header.showSearchHints?'-circle-fill':''} btn-icon btn-icon-lg fs-4`} onClick={header.toggleSearchHints}></i>
            </div>
          }
          { authed &&
            <>
            <button class="navbar-toggler border-0 text-bg-dark p-0 px-1" style={{marginTop:1.5}} data-bs-toggle="collapse" data-bs-target="#headerDropdown">
              <i class={`bi bi-list btn-icon btn-icon-lg fs-4`} />
            </button>
            <div class="navbar-collapse collapse flex-grow-0" id="headerDropdown" ref={menuRef}>
              <ul class="navbar-nav mt-25 mb-0">
                {navLink({link:'/dashboard', text:'Map', currLocation, classes:`${classes} rounded-top-3`})}
                {navLink({link:'/lists', text:'Lists', currLocation, classes})}
                {navLink({link:'/tags', text:'Tags', currLocation, classes})}
                {navLink({link:'/account', text:'Account', currLocation, classes})}
                {navLink({link:'/feedback', text:'Feedback', currLocation, classes:`${classes} rounded-bottom-3`})}
              </ul>
            </div>
            </>
          }
          </>
        }
        { !authed &&
          <div class="btn-group ms-1 ms-sm-3">
            <Link to="/signup" class="btn btn-primary">Sign up</Link>
            <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" style={{filter:'brightness(0.9)'}} data-bs-toggle="dropdown" />
            <ul class="dropdown-menu">
              <li><Link class="dropdown-item" to="/signin">Log in</Link></li>
            </ul>
          </div>
        }
        <div class="navbar-collapse collapse flex-grow-0 text-center" ref={alertRef}>
          <div class={`alert alert-success d-flex align-items-center mt-25 mb-0`}>
            <i class="bi bi-clipboard me-2"></i>
            <div>Copied <strong>{header.getNumPlaces()} places</strong> to the clipboard</div>
          </div>
        </div>
        </>
      }
    </header>
  );
}

function RequireAuth({children}) {
  const navigate = useNavigate();
  const { authed, check } = useAuth();
  const { setUser } = useData('user', {authed});

  if(authed===true) {
    return children;
  }
  else {
    console.log('auth check');
    check().then((user) => {
      if(!!user) {
        setUser(user);
        navigate("/dashboard");
      }
      else {
        navigate("/signin");
      }
    });
    return (<Loading />);
  }
}

export default function App() {
  const { authed } = useAuth();
  const location = useLocation();
  const currLocation = location.pathname;

  const onLogin = currLocation==='/signin' || currLocation==='/signup';
  const onDashboard = currLocation==='/dashboard' || currLocation==='/';
  const isStandalone = isAppStandalone();

  const [searchText, setSearchText] = useState('');
  const [view, setView] = useState('map');
  const [showSearchHints, setShowSearchHints] = useState(false);
  const [numLists, setNumLists] = useState(null);

  const isStandaloneUnauth = isStandalone && !authed;

  return (
    <HeaderContext.Provider value={{
      searchText, setSearchText,
      view, setView,
      showSearchHints, setShowSearchHints,
      numLists, setNumLists,
    }}>
      <div class={`d-flex flex-column h-100 ${isStandaloneUnauth?'justify-content-center bg-success':''}`}>
        { !isStandaloneUnauth ? /* app & mobile web landing pages */
          <>
          <Header />
          { onDashboard ?
            <div class="flex-grow-1 position-relative" style={{height:1}}>
              <Routes>
                {/* not authed */}
                <Route path="/" element={authed ? <Dashboard/> : <Dashboard mode={"demo"}/>} />

                {/* authed */}
                <Route path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>} />
              </Routes>
            </div>
          :
            <div class="app-content container-fluid flex-grow-1 mt-4 mt-sm-5">
              <div class="row justify-content-center"><div class="col-11">
                <Routes>
                  {/* not authed */}
                  <Route path="/signin" element={<Login/>} />
                  <Route path="/signup" element={<Login mode={"signup"}/>} />
                  <Route path="/finishSignin" element={<RequireAuth/>} />

                  {/* authed */}
                  <Route path="/lists" element={<RequireAuth><Lists/></RequireAuth>} />
                  <Route path="/tags" element={<RequireAuth><Tags/></RequireAuth>} />
                  <Route path="/feedback" element={<RequireAuth><Feedback/></RequireAuth>} />
                  <Route path="/account" element={<RequireAuth><Account/></RequireAuth>} />
                </Routes>
              </div></div>
            </div>
          }
          </>
        : /* standalone app landing page */
          <div class="text-center">
            <div class={`d-inline-block ${onLogin?'panel p-4 py-5':''}`} style={{marginTop:-25}}>
              <Routes>
                {/* root */}
                <Route path="/" element={<LandingStandalone/>} />

                {/* login pages */}
                <Route path="/signin" element={<Login/>} />
                <Route path="/signup" element={<Login mode={"signup"}/>} />
                <Route path="/finishSignin" element={<RequireAuth/>} />
              </Routes>
            </div>
          </div>
        }
      </div>
    </HeaderContext.Provider>
  );
}

