import { Link } from "react-router-dom";

export default function LandingStandalone() {
  return (
    <>
    <div class="mb-4">
      <img src="/images/mapIcon.svg" class="w-50" alt="" />
    </div>
    <div class="text-start panel p-4 py-45 mx-4 my-3 mb-45">
      <h1 class="fw-medium mb-4 opacity-100" style={{fontSize:'3.5vh'}}>Power tools for Google Maps</h1>
      <p class="fs-4 fw-light opacity-50 mb-0">Supercharge how you search, filter, tag, and track your Google saved places. Plan the best trips, date nights, and more!</p>
    </div>
    <div class="">
      <Link to="/signup" class="d-block">
        <button className="btn btn-action bg-light text-muted btn-lg">Sign up <i class="bi bi-chevron-right ms-1 fs-6"></i></button>
      </Link>
      <Link to="/signin" class="d-block text-white opacity-50 mt-2 fs-5">
        Log in
      </Link>
    </div>
    </>
  );
}