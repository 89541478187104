import { useState } from "react";

import { capitalizeFirstLetter } from '../common/utility';

export default function Combobox({tags, onSubmit}) {
  const [ text, setText ] = useState('');
  const [ selected, setSelected ] = useState(false);
  const [ optionIndex, setOptionIndex ] = useState(0);
  
  function setTextOuter(newText) {
    setText(newText);
    setSelected(false);
    setOptionIndex(0);
  }
  function resetText() {
    setTextOuter('');
  }
  function handleTextClear() {
    resetText();
  }
  function handleTextChange(e) {
    const newText = e.target.value;
    setTextOuter(newText);
  }
  function onKeyDown(e) {
    switch(e.key) {
    case 'Enter':
      if(filteredTags.length) {
        setSelected(true);
        const tag = filteredTags[optionIndex];
        setText(tag);
        submit(tag);
      }
      else {
        submit(text);
      }
      e.preventDefault();
      break;
    case 'ArrowUp':
      setOptionIndex(Math.max(0, optionIndex-1));
      e.preventDefault();
      break;
    case 'ArrowDown':
      setOptionIndex(Math.max(filteredTags.length-1, optionIndex+1));
      e.preventDefault();
      break;
    default:
      // only handle nav here
    }
  }
  function submit(tag) {
    resetText();
    if(onSubmit)
      onSubmit(tag.toLowerCase());
  }
  function tagClick(tag) {
    return ()=>{
      setSelected(true);
      setText(tag);
      submit(tag);
    };
  }
  
  var filteredTags = [];
  if(text) {
    filteredTags = tags.filter(t=>t.includes(text.toLowerCase()));
  }
  
  return (
    <div class="position-relative">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Tag name..." onChange={handleTextChange} value={text} onKeyDown={onKeyDown} />
        { !!text &&
          <button class="btn btn-outline-secondary" type="button" id="button-addon2" onClick={handleTextClear}>
            <i class="bi bi-x btn-icon"></i>
          </button>
        }
      </div>
      { !!filteredTags.length && !selected && 
        <ul class="dropdown-menu w-100" style={{display:'initial'}}>
          { filteredTags.map((tag, i)=>
            <li><span class={`dropdown-item ${i===optionIndex?'active':''}`} key={i} onClick={tagClick(tag)}>{capitalizeFirstLetter(tag)}</span></li>
          ) }
        </ul>
      }
    </div>
  );
}