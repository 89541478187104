import { Link } from "react-router-dom";
import { useRef, useState } from "react";

import Loading from "./loading.component";
import NeedSubscribe from "./needSubscribe.component";

import UserDataService from "../services/user.service";

import useAuth from "../hooks/auth.hook";
import useData from "../hooks/data.hook";
import useTimer from "../hooks/timer.hook";

export default function Lists(props) {
  const { authed } = useAuth();
  const { user } = useData('user', {authed});
  const { mapData, processResult, forceReloadMapData } = useData('mapData');
  const alertTimer = useTimer();
  const refreshTimer1 = useTimer();
  const refreshTimer2 = useTimer();

  const [showHelp, setShowHelp] = useState(false);

  const listUrl = useRef();
  const [alert, setAlert] = useState(null);
  function showAlert(message, type='primary') {
    setAlert({
      message,
      type
    });
    alertTimer.fn(() => {
      setAlert(null);
    }, 3000);
  }
    
  console.log('Lists - render', mapData);
  
  // loading screen
  if(!authed || !user) {
    return (<Loading />);
  }
  else if(!mapData) {
    if(!!user.subscription) {
      return (<Loading />);
    }
    else {
      return (<NeedSubscribe user={user} />);
    }
  }
  
  // list actions
  function refreshLists() {
    forceReloadMapData();
  }
  function addList() {
    const url = listUrl.current.value;
    if(url==='') {
      showAlert('Please enter a valid URL');
      return;
    }
    UserDataService.addList(url)
    .then(result => {
      processResult(result);
      listUrl.current.value = '';
      showAlert('List added, loading data now');
      refreshTimer1.fn(() => {
        forceReloadMapData();
      }, 5000);
      refreshTimer2.fn(() => {
        forceReloadMapData();
      }, 10000);
    });
  }
  function deleteList(id) {
    return ()=>{
      UserDataService.deleteList(id)
      .then(result => {
        processResult(result);
        showAlert('List deleted');
      });
    };
  }    
  
  // help
  function toggleShowHelp() {
    if(haveLists)
      setShowHelp(!showHelp);
  }
  function helpStep(num, caption, useImage=true) {
    return (
    <div class={`col-6 p-3 p-sm-4 ${(num%2)?'ps-2 ps-sm-0':'pe-2 pe-sm-0'}`}>
      <div class="text-muted fw-medium mb-3">
        {num}. {caption}
      </div>
      { useImage &&
        <img src={`addListSteps/addList${num}.png`} class="w-100" alt="" />
      }
    </div>
    );
  }
  
  const haveLists = mapData?.lists?.length>0;
  const shouldShowHelp = showHelp || !haveLists;
  const ellipsisStyle = {textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap', width:'47.5%'};
  return (
    <>
      { !!alert &&
        <div class={`alert alert-${alert.type} d-flex align-items-center`}>
          <i class="bi bi-exclamation-triangle-fill me-2"></i>
          <div>{alert.message}</div>
        </div>
      }
      <div class="p-425 p-sm-5 bg-light rounded-3">
        <h1 class="mb-4">
          { haveLists ? 'Manage Lists' : 'Add your first list' }
          <span class="float-end">
            { haveLists &&
              <i class="bi bi-arrow-clockwise btn-icon me-2 me-sm-3" onClick={refreshLists}></i>
            }
            <i class={`bi bi-question${shouldShowHelp?'-circle-fill':''} btn-icon`} onClick={toggleShowHelp}></i>
          </span>
        </h1>
        <div class="p-3 py-2 p-sm-45 py-sm-4 bg-white rounded-3 mb-3">
          { !shouldShowHelp ?
            <table class="table mb-0 w-100 h-100" style={{tableLayout:'fixed'}}>
              <tbody>
                { mapData.lists.map((list, index)=>(
                <tr class="mb-0" key={index}>
                  <td class={`fs-5 fs-sm-3 pe-2 ${!!list.name?'':'text-muted fw-light'}`} style={ellipsisStyle}>{list.name ?? 'Loading...'}</td>
                  <td class="fs-5 fs-sm-3 fw-light pe-2 d-none d-sm-table-cell" style={ellipsisStyle}><Link to={list.url} target="_blank">{list.url}</Link></td>
                  <td class="fs-5 fs-sm-3 text-muted text-end" style={{verticalAlign:'middle'}}>
                    <i class="bi bi-trash btn-icon" onClick={deleteList(list.id)}></i>
                  </td>
                </tr>
                )) }
              </tbody>
            </table>
          :
            <div class="row">
              <div class="">
                How to import your Google saved places list:
              </div>
              { helpStep(1, `Go to Google Maps on your computer, then click the 'hamburger' icon in the upper left`) }
              { helpStep(2, `Click 'Saved' to open your saved lists`) }
              { helpStep(3, `Click the three dots next to the list you would like to import`) }
              { helpStep(4, `Click 'Send the link to view'`) }
              { helpStep(5, `When the box pops up, click 'Get link to share'. If you don't see this option, skip to step 7'`) }
              { helpStep(6, `Click 'Get link'`) }
              { helpStep(7, `Click 'Copy link'`) }
              { helpStep(8, `Return to Map Tools, paste your link into the New List box (below), and click 'Import'`) }
              { helpStep(9, `Your list should be imported in about 10 seconds! Click the refresh button to see your new list`, false) }
            </div>
          }
        </div>
        <div class="d-flex py-3">
          <label for="listUrl" class="text-muted fs-4 pe-4" style={{whiteSpace:'nowrap'}}>New list</label>
          <div class="flex-fill">
            <input type="text" class="form-control h-100" id="listUrl" ref={listUrl} />
          </div>
        </div>
        <div class="text-end">
          <button type="button" class="btn btn-primary px-4" onClick={addList}>Import</button>
        </div>
      </div>
    </>
  );
}