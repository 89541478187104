import { assert, getSubscriptionLink } from '../common/utility';

export default function NeedSubscribe(user) {
  function goSubscribe() {
    assert(user);
    window.location = getSubscriptionLink(user);
  }

  return (
    <div class="px-5">
      <h3 class="fw-normal">You must subscribe to use Map Tools</h3>
      <button class="btn btn-primary signup-btn mt-3" onClick={goSubscribe}>Subscribe</button>
    </div>
  );
}